import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import Layout from '@solid-ui-layout/Layout'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import { Helmet } from "react-helmet";

const Login = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  useEffect(() => {
    if (window !== undefined) {
      window.location.href = 'https://rimor.juit.io/login'
    }
  }, []);

  return (
    <Layout {...props}>
      <Helmet>
        {/* <!-- Primary Meta Tags --> */}
        <title>JUIT | Login</title>
        <meta name="title" content="JUIT | Login" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="pt-br" />
        <meta name="revisit-after" content="30 days" />
        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://juit.io/" />
        <meta property="og:title" content="JUIT | Login" />
        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://juit.io/" />
        <meta property="twitter:title" content="JUIT | Login" />
        <link rel="apple-touch-icon" sizes="180x180" href="../../../site/content/assets/flexiblocks/icons/favIcons/apple-icon-180x180.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="../../../site/content/assets/flexiblocks/icons/favIcons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="../../../site/content/assets/flexiblocks/icons/favIcons/favicon-16x16.png" />
      </Helmet>
    </Layout>
  )
}

export const query = graphql`
  query innerpageSiteLoginBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/login", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default Login
